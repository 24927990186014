<template>
  <div id="filterReplace">
    <el-dialog
      :title="filterReplaceFormTitle"
      width="500px"
      :visible.sync="filterReplaceDialogVisible"
      :close-on-click-modal="false"
      @close="filterReplaceDialogClose"
    >
      <el-form
        ref="filterReplaceFormRef"
        :model="filterReplaceForm"
        :rules="filterReplaceFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-form-item label="操作日期" prop="operationDate">
          <el-date-picker v-model="filterReplaceForm.operationDate" placeholder="请选择操作日期" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="更换位置" prop="changeLocation">
          <el-radio-group v-model="filterReplaceForm.changeLocation">
            <el-radio :label="1">
              保安过滤器
            </el-radio>
            <el-radio :label="2">
              精密过滤器
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="滤芯精度" prop="filterAccuracy">
          <el-radio-group v-model="filterReplaceForm.filterAccuracy">
            <el-radio :label="1">
              5μm
            </el-radio>
            <el-radio :label="2">
              0.22μm
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="更换后使用状态" prop="useStatus">
          <el-radio-group v-model="filterReplaceForm.useStatus">
            <el-radio :label="1">
              正常
            </el-radio>
            <el-radio :label="2">
              异常
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="操作人" prop="operator">
          <el-input v-model="filterReplaceForm.operator" placeholder="请输入操作人" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="filterReplaceDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="filterReplaceFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="操作日期">
        <el-date-picker v-model="searchForm.operationDate" placeholder="请选择操作日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="filterReplacePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="操作日期">
        <template slot-scope="scope">
          <span v-if="scope.row.operationDate">{{ scope.row.operationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="更换位置">
        <template slot-scope="scope">
          <span v-if="scope.row.changeLocation === 1">保安过滤器</span>
          <span v-if="scope.row.changeLocation === 2">精密过滤器</span>
        </template>
      </el-table-column>
      <el-table-column label="滤芯精度">
        <template slot-scope="scope">
          <span v-if="scope.row.filterAccuracy === 1">5μm</span>
          <span v-if="scope.row.filterAccuracy === 2">0.22μm</span>
        </template>
      </el-table-column>
      <el-table-column label="更换后使用状态">
        <template slot-scope="scope">
          <span v-if="scope.row.useStatus === 1">正常</span>
          <span v-if="scope.row.useStatus === 2">异常</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="filterReplacePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addFilterReplace, deleteFilterReplace, updateFilterReplace, selectFilterReplaceInfo, selectFilterReplaceList } from '@/api/device/filterReplace'

export default {
  data () {
    return {
      filterReplaceDialogVisible: false,
      filterReplaceFormTitle: '',
      filterReplaceForm: {
        id: '',
        operationDate: '',
        changeLocation: '',
        filterAccuracy: '',
        useStatus: '',
        operator: ''
      },
      filterReplaceFormRules: {
        operationDate: [{ required: true, message: '操作日期不能为空', trigger: ['blur', 'change']}],
        changeLocation: [{ required: true, message: '更换位置不能为空', trigger: ['blur', 'change']}],
        filterAccuracy: [{ required: true, message: '滤芯精度不能为空', trigger: ['blur', 'change']}],
        useStatus: [{ required: true, message: '更换后使用状态不能为空', trigger: ['blur', 'change']}],
        operator: [{ required: true, message: '操作人不能为空', trigger: ['blur', 'change']}]
      },
      filterReplacePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        operationDate: ''
      }
    }
  },
  created () {
    selectFilterReplaceList(this.searchForm).then(res => {
      this.filterReplacePage = res
    })
  },
  methods: {
    filterReplaceDialogClose () {
      this.$refs.filterReplaceFormRef.resetFields()
    },
    filterReplaceFormSubmit () {
      if (this.filterReplaceFormTitle === '水系统滤芯更换记录详情') {
        this.filterReplaceDialogVisible = false
        return
      }
      this.$refs.filterReplaceFormRef.validate(async valid => {
        if (valid) {
          if (this.filterReplaceFormTitle === '新增水系统滤芯更换记录') {
            await addFilterReplace(this.filterReplaceForm)
          } else if (this.filterReplaceFormTitle === '修改水系统滤芯更换记录') {
            await updateFilterReplace(this.filterReplaceForm)
          }
          this.filterReplacePage = await selectFilterReplaceList(this.searchForm)
          this.filterReplaceDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.filterReplaceFormTitle = '新增水系统滤芯更换记录'
      this.filterReplaceDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFilterReplace(row.id)
        if (this.filterReplacePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.filterReplacePage = await selectFilterReplaceList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.filterReplaceFormTitle = '修改水系统滤芯更换记录'
      this.filterReplaceDialogVisible = true
      this.selectFilterReplaceInfoById(row.id)
    },
    handleInfo (index, row) {
      this.filterReplaceFormTitle = '水系统滤芯更换记录详情'
      this.filterReplaceDialogVisible = true
      this.selectFilterReplaceInfoById(row.id)
    },
    selectFilterReplaceInfoById (id) {
      selectFilterReplaceInfo(id).then(res => {
        this.filterReplaceForm.id = res.id
        this.filterReplaceForm.operationDate = res.operationDate
        this.filterReplaceForm.changeLocation = res.changeLocation
        this.filterReplaceForm.filterAccuracy = res.filterAccuracy
        this.filterReplaceForm.useStatus = res.useStatus
        this.filterReplaceForm.operator = res.operator
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFilterReplaceList(this.searchForm).then(res => {
        this.filterReplacePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFilterReplaceList(this.searchForm).then(res => {
        this.filterReplacePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFilterReplaceList(this.searchForm).then(res => {
        this.filterReplacePage = res
      })
    }
  }
}
</script>

<style>
</style>
