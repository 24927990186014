import axios from '@/common/axios'
import qs from 'qs'

export function addFilterReplace (data) {
  return axios({
    method: 'post',
    url: '/device/filterReplace/add',
    data: qs.stringify(data)
  })
}

export function deleteFilterReplace (id) {
  return axios({
    method: 'delete',
    url: '/device/filterReplace/delete/' + id
  })
}

export function updateFilterReplace (data) {
  return axios({
    method: 'put',
    url: '/device/filterReplace/update',
    data: qs.stringify(data)
  })
}

export function selectFilterReplaceInfo (id) {
  return axios({
    method: 'get',
    url: '/device/filterReplace/info/' + id
  })
}

export function selectFilterReplaceList (query) {
  return axios({
    method: 'get',
    url: '/device/filterReplace/list',
    params: query
  })
}
